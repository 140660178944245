<template>
    <request :request="request" @on-success="onRequestSuccess">
        <div class="text-left" v-if="user">
            <div v-if="user.profile">
                <div class="text-h4">{{ user.profile.first_name || user.first_name }} {{ user.profile.last_name || user.last_name  }}</div>
                <div class="text-h6 grey--text">{{ user.email }}</div>
            </div>
            <div v-else>
                <div class="text-h4">{{ user.first_name }} {{ user.last_name  }}</div>
                <div class="text-h6 grey--text">{{ user.email }}</div>
            </div>

            <v-card class="mt-4">
                <v-card-title>Data</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tr>
                                        <th>Client</th>
                                        <td><v-chip v-if="user.client" color="blue" dark small>{{  user.client.name }}</v-chip></td>
                                    </tr>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col>
                            <v-simple-table v-if="user.profile">
                                <tr v-for="(value, property) in user.profile.properties" :key="property">
                                    <th>{{ property | snakeToTitleCase }}</th>
                                    <td>{{ value }}</td>
                                </tr>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>RBAC</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            Available RBACs
                        </v-col>
                        <v-col>
                            Assigned RBACs
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </request>
</template>

<script>
    import ManageAPI from '../webapi';

    export default {
        name: 'ManageUserSingle',

        props: {
            userId: {
                type: [String, Number],
                required: true,
            },
        },

        created() {
            this.request = () => ManageAPI.getUser(parseInt(this.userId, 10));
        },

        data: () => ({
            request: undefined,
            user: undefined,
        }),

        methods: {
            onRequestSuccess(data) {
                this.user = data;
            },
        },
    };
</script>
